import {useEffect, useRef, useState} from 'react';
import time from '@/utils/helpers/time.ts';

interface JenkinsBuilds {
  duration: string;
  fullDisplayName: string;
  result: string;
  timestamp: number;
}

const AdminMain = () => {
  // const [jobName, setJobName] = useState('me-admin');
  const jobName = useRef('me-admin');
  const [builds, setBuilds] = useState<JenkinsBuilds[]>()

  useEffect(() => {
    fetch(
      `https://jenk1ns.ddonggra.me/job/${jobName.current}/job/main/api/json?tree=allBuilds[fullDisplayName,result,duration,timestamp]`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Basic ${btoa('ddonggrame:116c4fe64178d30e8f0827691e80238e9d')}`,
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json(); // 응답을 JSON으로 변환
      })
      .then(data => {
        console.log('Success:', data.allBuilds); // JSON 데이터를 처리
        setBuilds(data.allBuilds.splice(0, 3));
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [jobName.current]);

  return (
    <div className="wrap-admin">
      <div className="main-info">
        <div className="count">
          <div className="count-row">
            <div>등록 게시글</div>
            <div>?</div>
          </div>
          <div className="count-row">
            <div>관리 카테고리</div>
            <div>?</div>
          </div>
          <div className="count-row">
            <div>등록 포트폴리오</div>
            <div>?</div>
          </div>
        </div>
        <div className="recent-status">차트 영역</div>
      </div>
      <div className="main-info">
        <div className="wrap-deploy">
          <div className="title">{jobName.current}</div>

          <ul className="deploy header">
            <li className="id">배포 ID</li>
            <li className="time">기간</li>
            <li className="result">결과</li>
            <li className="date">배포 날짜</li>
          </ul>

          {
            builds &&
            builds.map((build: JenkinsBuilds) => {
              return (
                <ul className="deploy" key={ build.fullDisplayName }>
                  <li className="id">{build?.fullDisplayName.split(`${jobName.current} » main `)}</li>
                  <li className="time">{time.convertToSecond(build.duration)}초</li>
                  <li className={ `result ${ build.result }` }>
                    <span>{build?.result}</span>
                  </li>
                  <li className="date">{ time.convertDateFormat('YYYY-MM-DD', String(build?.fullDisplayName.split(`${jobName.current} » main `)[1]))}</li>
                </ul>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default AdminMain;
